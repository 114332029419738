<template>
  <b-modal
    :id="id"
    title="Cobro con CoDi"
    hide-footer
    centered
    no-close-on-backdrop
    no-close-on-esc
  >
    <div
      v-if="codiResponseType === 'qrcode'"
      class="p-2 d-flex flex-column align-items-center justify-content-center"
    >
      <p class="mb-2">
        Abre tu aplicación bancaria, dirígete a la sección de CoDi y escanea el
        código QR. También puedes guardar o hacer una captura de pantalla y
        subirla a tu aplicación bancaria dentro de la sección de CoDi.
      </p>
      <b-img :src="codiQrcode" alt="CoDi QR Code" fluid class="rounded" />
    </div>
    <div
      v-else
      class="p-2 d-flex flex-column align-items-center justify-content-center"
    >
      <p class="mb-2">
        Al recibir la notificación de cobro en tu celular ábrela y accede a tu
        aplicación bancaria. Acepta el pago. Si deseas cancelar tu compra cierra
        este mensaje.
      </p>
      <b-spinner big class="mt-4" label="Esperando el pago" />
      <p class="py-2 mt-4 ">Si deseas cancelar tu compra cierra este mensaje.</p>
    </div>
  </b-modal>
</template>

<script>
export default {
  props: {
    id: {
      type: String,
      default: "codi-purchase-qrcode",
    },
    codiQrcode: {
      type: String,
      default: "",
    },
    codiResponseType: {
      type: String,
      default: "",
    },
  },
}
</script>
