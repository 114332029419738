<template>
  <div class="product-card mb-0 px-50">
    <div class="p-1">
      <div class="mr-2 d-flex align-items-center">
        <b-img
          :alt="`${product.name}-${product.id}`"
          fluid
          rounded
          class="product__image"
          :src="`${product.logo}`"
        />
      </div>
      <div class="bold line-clamp-6 h4 mb-0 pb-0" text-variant="primary">
        {{ product.name | capitalize }}
      </div>

      <small v-if="product.variant" class="text-secondary">
        {{ product.variant }}
      </small>
      <small
        v-if="selectedStoreProduct.unit_code_name"
        class="d-block text-secondary"
      >
        {{ selectedStoreProduct.unit_code_name }}
      </small>

      <div
        v-if="product.is_edible && product.nutritional_info"
        class="item-wrapper mt-auto py-50"
      >
        <div class="item-rating">
          <nutri-score :nutritional-info="product.nutritional_info" />
        </div>
      </div>

      <div
        class="product-color-option pb-1 mt-1"
        v-if="product.categories_names && product.categories_names.length > 0"
      >
        <h6>Categorias</h6>
        <ul class="list-unstyled m-0">
          <li
            v-for="category in product.categories_names"
            :key="`category-${category}`"
            class="d-inline-block"
          >
            <b-badge class="whitespace-normal">{{ category }}</b-badge>
          </li>
        </ul>
      </div>

      <div v-if="selectedStoreProduct" class="item-wrapper mt-50">
        <div class="item-cost">
          <h5 class="bold item-price text-body d-flex justify-content-between">
            ${{ selectedStoreProduct.unit_price | money }}
          </h5>
        </div>
      </div>

      <div v-if="selectedStoreProduct && selectedStoreProduct.is_bundle">
        <b-badge pill variant="light-info">
          {{
            selectedStoreProduct.bundle_name +
            " de " +
            selectedStoreProduct.bundle_quantity
          }}
        </b-badge>
      </div>

      <div v-if="bestPromotion" class="pt-1">
        <b-badge
          v-if="bestPromotion.promo_type !== 'free_product'"
          pill
          :variant="'light-info'"
          class="text-break text-wrap"
        >
          <span>
            {{ "Compra " + bestPromotion.needs_to_buy + " y recibe" }}
          </span>
          <span v-if="bestPromotion.promo_type === 'price_discount'">
            {{ bestPromotion.discount + "% de descuento" }}
          </span>
          <span v-else-if="bestPromotion.promo_type === 'reward_points'">
            {{ bestPromotion.reward_points + "% en puntos" }}
          </span>
        </b-badge>

        <!-- Free product -->
        <b-badge
          v-if="bestPromotion.promo_type === 'free_product'"
          pill
          :variant="'light-info'"
        >
          {{ bestPromotion.needs_to_buy }} {{ "x" }}
          {{ bestPromotion.gets_you }}
        </b-badge>
      </div>

      <div class="item-wrapper" v-if="selectedStoreProduct.has_inventory">
        <div class="item-units d-flex justify-content-between">
          <span class="mr-1">
            Inventario: {{ Number.parseInt(selectedStoreProduct.inventory) }}
          </span>
        </div>
        <div
          v-if="Number(selectedStoreProduct.inventory) === 0"
          class="item-units text-danger d-flex justify-content-between"
        >
          <span class="mr-1">Agotado</span>
        </div>
      </div>

      <div v-if="product.store_products.length > 1" class="mt-1">
        <span> * Elige una opción: </span>
        <b-form-select v-model="selectedVariant" @click.native.stop>
          <b-form-select-option
            v-for="(option, index) in storeProductOptions"
            :key="index"
            :value="option.value"
          >
            {{ option.text }}
          </b-form-select-option>
        </b-form-select>
      </div>

      <div class="py-1 cursor-pointer">
        <b-input-group>
          <b-input-group-prepend
            :disabled="units === 1"
            @click.stop="handleMinus"
          >
            <b-input-group-text>
              <i class="fa fa-lg fa-minus" />
            </b-input-group-text>
          </b-input-group-prepend>
          <b-form-input
            v-model="units"
            type="number"
            @click.stop="
              $bvModal.show(
                `bascula-modal-${selectedStoreProduct.id}-${uniqueId}`
              )
            "
          />
          <b-input-group-append @click.stop="handlePlus">
            <b-input-group-text>
              <i class="fa fa-lg fa-plus" />
            </b-input-group-text>
          </b-input-group-append>
        </b-input-group>
      </div>
    </div>

    <bascula-modal
      v-if="selectedStoreProduct"
      :product="selectedStoreProduct"
      :uniqueId="uniqueId"
      @edit-units="units = $event"
    />
  </div>
</template>

<script>
import vSelect from "vue-select"

import NutriScore from "../NutriScore.vue"
import BasculaModal from "@core/components/bascula-modal.vue"

import { getBestPromotion } from "@/@core/utils/store-product-utils"

export default {
  components: {
    NutriScore,
    BasculaModal,
    vSelect,
  },
  props: {
    product: {
      type: Object,
      default: () => ({}),
    },
    storeProduct: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      selectedStoreProduct: {
        ...this.storeProduct,
        units: 1,
      },
      units: 1,
      selectedVariant: this.storeProduct.id,
      uniqueId: Math.random().toString(36).substring(7),
    }
  },
  watch: {
    selectedStoreProduct: {
      handler() {
        if (
          this.storeProduct.id === this.selectedStoreProduct.id &&
          this.units === 1
        )
          return

        this.$emit("update:selectedStoreProduct", this.selectedStoreProduct)
      },
      deep: true,
    },
    units: {
      handler() {
        this.selectedStoreProduct.units = this.units
      },
    },
    selectedVariant: {
      handler() {
        this.handleStoreProductChange()
      },
    },
    storeProduct: {
      handler() {
        if (this.storeProduct.id === this.selectedStoreProduct.id) return

        this.selectedStoreProduct = {
          ...this.storeProduct,
          units: 1,
        }
      },
      deep: true,
    },
  },
  computed: {
    bestPromotion() {
      if (
        !this.selectedStoreProduct ||
        !this.selectedStoreProduct.promotion_attributes
      )
        return

      return this.getBestPromotion(this.selectedStoreProduct)
    },

    storeProductOptions() {
      if (
        !this.product.store_products ||
        this.product.store_products.length < 2
      ) {
        return
      }

      return (
        this.product?.store_products?.map((store_product) => {
          const variantNames =
            store_product.store_product_variant_attributes
              ?.map(
                (variant) =>
                  variant.variant_option_attributes?.option_name || ""
              )
              .join(" ") || `$${store_product.unit_price}`
          return {
            value: store_product.id,
            text: variantNames,
          }
        }) || []
      )
    },
  },
  methods: {
    getBestPromotion,

    handlePlus() {
      this.units++
    },
    handleMinus() {
      if (this.units > 1) {
        this.units--
      }
    },

    handleStoreProductChange() {
      this.selectedStoreProduct = {
        ...this.product.store_products.find(
          (storeProduct) => storeProduct.id === this.selectedVariant
        ),
        units: 1,
      }
    },
  },
}
</script>

<style lang="scss">
@import "~@core/scss/base/pages/app-ecommerce.scss";
</style>

<style lang="scss" scoped>
.product-card {
  display: flex;
  flex-direction: column;
}

.scrollable-wrapper {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  white-space: nowrap;
}

.btn-cart-small {
  display: block;
  position: absolute;
  border-radius: 50%;
  right: 20px;
  top: 20px;
  z-index: 1;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn-text {
  font-size: 0.8rem;
}

.btn-cart {
  display: none;
}

@media (min-width: 768px) {
  .btn-text {
    font-size: 1rem;
  }

  .btn-cart {
    display: block;
  }

  .btn-cart-small {
    display: none;
  }
}

.product__image {
  margin: 0 auto;
  height: 150px;
  width: auto;
}

.product-buttons {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5px;

  a {
    font-size: 1rem;
  }

  @media (max-width: 680px) {
    font-size: 1rem;

    a {
      font-size: 0.9rem;
    }
  }
}

.bold {
  font-weight: 600;
}
</style>
