<template>
  <div v-if="isActive">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "WizardStep",
  props: {
    beforeChange: {
      type: Function,
      default: () => true,
    },
    showStep: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    isActive() {
      return this.$parent.activeStep === this.$parent.steps.indexOf(this)
    },
  },
}
</script>

<style></style>
