<template>
  <div class="form-wizard" :class="wizardClass">
    <div v-if="buttonsPosition === 'top'" class="buttons" :class="buttonsClass">
      <b-button
        v-if="activeStep > 0"
        variant="outline-secondary"
        @click="prevStep"
      >
        Atrás
      </b-button>
      <b-button
        v-if="activeStep < steps.length - 1"
        class="ml-auto"
        variant="primary"
        @click="nextStep"
      >
        Siguiente
      </b-button>
      <b-button v-else class="ml-auto" variant="primary" @click="complete">
        Confirmar
      </b-button>
    </div>
    <div class="steps" :class="bodyClass">
      <slot></slot>
    </div>
    <div
      v-if="buttonsPosition === 'bottom'"
      class="buttons"
      :class="buttonsClass"
    >
      <b-button
        v-if="activeStep > 0"
        variant="outline-secondary"
        @click="prevStep"
      >
        Atrás
      </b-button>
      <b-button
        v-if="activeStep < steps.length - 1"
        class="ml-auto"
        variant="primary"
        @click="nextStep"
      >
        Siguiente
      </b-button>
      <b-button v-else class="ml-auto" variant="primary" @click="complete">
        Confirmar
      </b-button>
    </div>
  </div>
</template>

<script>
export default {
  name: "FormWizard",
  props: {
    wizardClass: {
      type: String,
      default: "",
    },
    stepsClass: {
      type: String,
      default: "",
    },
    bodyClass: {
      type: String,
      default: "",
    },
    buttonsClass: {
      type: String,
      default: "",
    },
    buttonsPosition: {
      type: String,
      default: "bottom",
    },
  },
  data() {
    return {
      activeStep: 0,
      steps: [],
    }
  },
  watch: {
    $children: {
      handler() {
        this.updateSteps()
      },
      deep: true,
    },
    activeStep() {
      this.updateSteps()
    },
  },
  mounted() {
    this.steps = this.$children.filter(
      (child) => child.$options.name === "WizardStep"
    )
  },
  methods: {
    updateSteps() {
      this.steps = this.$children.filter(
        (child) => child.$options.name === "WizardStep" && child.showStep
      )
    },
    async nextStep() {
      const currentStep = this.steps[this.activeStep]
      if (currentStep && currentStep.beforeChange) {
        let result = currentStep.beforeChange()

        if (result instanceof Promise) {
          result = await result
        }

        if (result === true) {
          this.activeStep++
        }
      }
    },
    prevStep() {
      this.activeStep--
    },
    complete() {
      this.$emit("on-complete")
    },
    navigateToStep(index) {
      this.activeStep = index
    },
  },
}
</script>

<style>
.steps {
  margin-bottom: 16px;
  overflow: auto;
}

.buttons {
  display: flex;
  justify-content: space-between;
}

button {
  padding: 8px 16px;
  cursor: pointer;
}
</style>
