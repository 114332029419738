<template>
  <div v-if="!loading">
    <LandingPageHeader>
      <template v-slot:cp>
        <div class="d-flex align-items-center">
          <feather-icon
            v-if="postalCode || currentDeliveryOption === 'not_applicable'"
            class="cursor-pointer mr-50 d-none d-md-flex"
            icon="XIcon"
            size="24"
            data-testid="postal-code-remove"
            @click="removePostalCode"
          />

          <b-button
            data-testid="postal-code-button"
            class="d-none d-md-flex ml-auto"
            size="sm"
            variant="outline-primary"
            @click="handleChangePostalCode"
          >
            <feather-icon
              icon="MapPinIcon"
              v-b-toggle.store-product-filters
              class="mr-50"
            />
            {{
              postalCode
                ? postalCode
                : currentDeliveryOption === "not_applicable"
                ? "No aplica"
                : "Seleccionar"
            }}
          </b-button>
        </div>
      </template>
    </LandingPageHeader>

    <div class="landing__banner container">
      <LandingPageBanner />
    </div>

    <StoreProductFilters
      :filters="filters"
      :storeTypes="storeTypes"
      :categories="categories"
      :products="storeProducts"
      :onChange="handleOnChangeFilters"
    />

    <main class="main__contaner container">
      <div class="searchbar-container my-3">
        <div class="d-flex align-items-center grow">
          <feather-icon
            icon="FilterIcon"
            size="21"
            v-b-toggle.store-product-filters
          />

          <Searchbar ref="searchbar" class="ml-1 grow" @onChange="setQuery" />
        </div>
        <div class="d-flex justify-content-between ml-auto align-items-center">
          <feather-icon
            v-if="postalCode || currentDeliveryOption === 'not_applicable'"
            class="cursor-pointer mr-50 d-md-none"
            icon="XIcon"
            size="24"
            @click="removePostalCode"
          />

          <b-button
            data-testid="postal-code-button-mobile"
            class="d-md-none mr-2"
            size="sm"
            variant="outline-primary"
            @click="handleChangePostalCode"
          >
            <feather-icon
              icon="MapPinIcon"
              v-b-toggle.store-product-filters
              class="mr-50"
            />
            {{
              postalCode
                ? postalCode
                : currentDeliveryOption == "not_applicable"
                ? "No aplica"
                : "Seleccionar"
            }}
          </b-button>
          <div class="d-flex align-items-center" v-if="storeOptions.length">
            <store-icon class="mr-50" />
            <b-form-select
              ref="storeSelect"
              :value="selectedStore"
              :options="storeOptions"
              class="w-fit"
              @change="handleStoreChange"
            ></b-form-select>
          </div>
        </div>
      </div>

      <StoreProductList
        :products="storeProducts"
        :withVariants="has_store"
        :handleAddToCart="handleAddToCart"
        :handleStoreProductSelection="handleStoreProductSelection"
      />

      <div
        class="d-flex justify-content-center py-4"
        v-if="storeProducts.length > 0"
      >
        <PaginationSimple
          :pagination="pagination"
          :handlePagination="handlePagination"
          :loading="loading || loadingProducts"
        />
      </div>
    </main>

    <!-- <ScrollToTop /> -->

    <PostalCodeModal
      :goBack="goBack"
      :isBackButton="true"
      :onSubmit="handlePostalCodeSubmit"
    />

    <DeliveryOptionsModal @update:deliveryOption="handleDeliveryOptionChange" />

    <StoreProductOptionsModal
      :selectedProduct="selectedProduct"
      @onSubmit="handleStoreProductOptionsSubmit"
    />
    <StoreProductDetailsModal :storeProduct="selectedStoreProduct" />

    <LoginModal successMessage="Bienvenido de nuevo!" />
    <LandingPageContactModal :establishment="establishment" />
  </div>
</template>

<script>
// Directives
import { mapActions, mapMutations, mapGetters } from "vuex"

// Components
import StoreIcon from "@/components/icons/store-icon.vue"
import ProductPromotionsModal from "@/@core/components/WallEateCommerce/ProductPromotionsModal.vue"

import StoreProductList from "@/@core/components/store-products/StoreProductList.vue"
import StoreProductFilters from "@/@core/components/store-products/StoreProductFilters.vue"
import StoreProductOptionsModal from "@/@core/components/store-products/StoreProductOptionsModal.vue"
import StoreProductDetailsModal from "@/@core/components/store-products/StoreProductDetailsModal.vue"

import LandingPageHero from "@/@core/components/landing-page/LandingPageHero.vue"
import LandingPageBanner from "@/@core/components/landing-page/LandingPageBanner.vue"
import LandingPageHeader from "@/@core/components/landing-page/LandingPageHeader.vue"
import LandingPageContactModal from "@/@core/components/landing-page/LandingPageContactModal.vue"

import Searchbar from "@/@core/components/Searchbar.vue"
import LoginModal from "@/@core/components/LoginModal.vue"
import ScrollToTop from "@/@core/components/scroll-to-top/ScrollToTop.vue"
import PostalCodeModal from "@/@core/components/PostalCodeModal.vue"
import PaginationSimple from "@/@core/components/PaginationSimple.vue"
import DeliveryOptionsModal from "@core/components/DeliveryOptionsModal.vue"

// Mixins
import {
  useShopRemoteData,
  useShopFiltersSortingAndPagination as useFilters,
} from "@/views/stores/useStoreProducts"
import cartMixin from "@/@core/mixins/cartMixin"
import messagesMixin from "@/@core/mixins/messagesMixin"

import { filterUniqueById } from "@/utils/utils"
import { beautyPromotion, getBestPromotion } from "@/utils/store-product-utils"

import useAppConfig from "@core/app-config/useAppConfig"

export default {
  components: {
    StoreIcon,
    StoreProductList,
    StoreProductFilters,
    StoreProductOptionsModal,
    StoreProductDetailsModal,

    LandingPageHero,
    LandingPageBanner,
    LandingPageHeader,
    LandingPageContactModal,

    ProductPromotionsModal,

    LoginModal,
    Searchbar,
    ScrollToTop,
    PostalCodeModal,
    PaginationSimple,
    DeliveryOptionsModal,
  },
  data() {
    return {
      loadingProducts: false,
      token: this.$route.query.token,
      store_id: this.$route.query.store_id,
      perPage: 20,
      loading: false,
      has_store: false,
      selectedStore: null,
      storeType: this.$route.query.store_type,
      previousStore: null,
      selectedStoreProduct: null,
      stopFiltering: false,
      categories: [],
      storeTypes: [],
      previousDeliveryOption: null,
      selectedProduct: null,
      deliveryOption: null,
      isMarketplace: this.$route.params.slug === "marketplace",
      fromStoreId: this.$route.query.from_store_id,
      deliveryOptions: [
        { value: "pickup", text: "Recoger en tienda" },
        { value: "delivery", text: "Entrega a domicilio" },
        { value: "both", text: "Ambos" },
      ],
      appLoading: document.getElementById("loading-bg"),
      postalCode: localStorage.getItem("postal_code") || null,
      showInstallPrompt: localStorage.getItem("install_prompt") !== "accepted",
      deferredPrompt: null,
      installToastId: "install-toast",
    }
  },
  mixins: [messagesMixin, cartMixin],
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.resetStates()
      vm.fetchInitialData()
    })
  },
  beforeRouteUpdate(to, from, next) {
    this.resetStates()
    this.fetchInitialData()
    next()
  },
  setup() {
    const { filters } = useFilters()

    const { fetchCategories } = useShopRemoteData()

    const { skin } = useAppConfig()

    return {
      skin,
      // Data
      filters,

      // Methods
      fetchCategories,
    }
  },
  watch: {
    currentDeliveryOption(val) {
      if (val) {
        this.previousDeliveryOption = val
      }
    },
    store: {
      handler(val) {
        if (!val) return

        if (this.currentStore) {
          this.emptyCart()
        }

        this.setCurrentStore(val)
        this.previousStore = this.store.id

        this.selectedStore = val.id

        this.loadingSwal({
          title: "Cargando productos",
        })

        this.getGroupedStoreProducts().finally(() => {
          this.$swal.close()
        })

        this.fetchCategories({
          by_active_status: true,
          by_store: this.store.id,
          meta: { pagination: { per_page: 10000 } },
        }).then((response) => {
          this.categories = response.data
        })

        this.fetchStoreAccount({ id: val.id })
      },
      immediate: true,
    },
  },
  created() {
    // window.addEventListener("beforeinstallprompt", (event) => {
    //   event.preventDefault()
    //   this.deferredPrompt = event
    //   this.showInstallToast()
    // })
  },
  destroyed() {
    this.resetStates()
    document.body.style.overflow = "auto"
  },
  methods: {
    ...mapActions("stores", [
      "fetchShortStores",
      "fetchShortStore",
      "fetchStoreTypes",
    ]),
    ...mapActions("landingPage", ["fetchLandingPage"]),
    ...mapActions("storeProducts", [
      "fetchPublicStoreProducts",
      "fetchGroupedStoreProducts",
    ]),
    ...mapActions("stripe", ["fetchStoreAccount"]),
    ...mapActions("cart", ["emptyCart", "addCartItem"]),

    ...mapMutations("storeProducts", [
      "setStoreProducts",
      "setAvailableStores",
    ]),
    ...mapMutations("landingPage", [
      "setStore",
      "setCurrentDeliveryOption",
      "setEstablishment",
    ]),
    ...mapMutations("stores", ["setCurrentStore", "setNearbyStores"]),

    installApp() {
      if (this.deferredPrompt) {
        this.deferredPrompt.prompt()
        this.deferredPrompt.userChoice.then((choiceResult) => {
          if (choiceResult.outcome === "accepted") {
            console.log("El usuario aceptó el prompt de instalación")
          } else {
            console.log("El usuario rechazó el prompt de instalación")
          }
          this.deferredPrompt = null
          localStorage.setItem("install_prompt", "accepted")
          this.showInstallButton = false
        })
      }

      this.$bvToast.hide(this.installToastId)
    },

    clearFilters() {
      this.$refs.searchbar.searchQuery = ""
      this.filters = {
        categories: [],
        nutriScore: null,
        q: null,
        storeTypes: [],
      }
    },

    handleDeliveryOptionChange(val) {
      if (!val) return

      this.deliveryOption = val

      if (val === "not_applicable") {
        this.handleDeliveryOptionNotApplicable()
      } else {
        this.$bvModal.show("ask-postal-code-modal")
      }
    },

    async handleOnChangeFilters() {
      if (
        (this.postalCode && this.store) ||
        (this.currentDeliveryOption === "not_applicable" && this.store) ||
        (this.store && (this.store_id || this.token))
      ) {
        this.getGroupedStoreProducts().finally(() => {
          this.$swal.close()
        })
      } else {
        this.getPublicStoreProducts(
          {
            by_distinct_product: true,
            by_category: this.filters.categories || null,
            by_nutritional_info: this.filters.nutriScore || null,
            by_name: this.filters.q || null,
            by_store: this.nearbyStoreIds,
            by_store_type: this.filters.storeTypes || null,
          },
          { page: 1 }
        ).finally(() => {
          this.$swal.close()
        })
      }
    },

    async handleStoreProductOptionsSubmit(newStoreProduct, selectedStore) {
      const bestPromotion = getBestPromotion(newStoreProduct)

      if (
        !bestPromotion ||
        (bestPromotion && Number(newStoreProduct.units)) >=
          Number(bestPromotion.needs_to_buy)
      ) {
        this.addCartItem(newStoreProduct)
      } else {
        const beautyPromotionText = beautyPromotion(bestPromotion)

        this.$swal({
          title: "Producto con oferta",
          text: `¿Deseas aplicar la oferta de ${beautyPromotionText}?`,
          icon: "warning",
          showDenyButton: true,
          confirmButtonText: "Si, aplicar",
          denyButtonText: "No",
          customClass: {
            confirmButton: "btn btn-primary",
            denyButton: "btn btn-outline-danger mr-1",
          },
          reverseButtons: true,
          buttonsStyling: false,
          allowOutsideClick: false,
          allowEscapeKey: false,
          allowEnterKey: false,
        }).then((result) => {
          if (result.isConfirmed) {
            const { needs_to_buy } = bestPromotion
            this.addCartItem({
              ...newStoreProduct,
              units: needs_to_buy,
            })
          } else if (result.isDenied) {
            this.addCartItem(newStoreProduct)
          }
        })
      }

      this.clearFilters()
      this.setStore(selectedStore)
      this.setCurrentDeliveryOption(this.deliveryOption)
      const res = await this.getNearbyStores({
        by_postal_code: this.postalCode || null,
        by_delivery_option: this.deliveryOption,
      })
      this.setNearbyStores(res.data)
    },

    async handleDeliveryOptionNotApplicable() {
      try {
        if (this.previousDeliveryOption === "not_applicable") {
          this.$bvModal.hide("delivery-options-modal")
          return
        }

        this.setNearbyStores([])
        this.postalCode = null
        this.loadingSwal()

        const filters = {
          by_delivery_option: this.deliveryOption,
        }

        this.setStore(null)
        this.emptyCart()
        this.setCurrentStore(null)

        if (this.selectedProduct) {
          const res = await this.getNearbyStores({
            by_product: this.selectedProduct.id,
            ...filters,
          })

          const uniqueStores = filterUniqueById(res.data)

          this.setAvailableStores(uniqueStores)
          this.$bvModal.show("store-product-options-modal")
          return
        }

        const nearbyStoresResponse = await this.getNearbyStores(filters)
        const storeIds = nearbyStoresResponse.data.map((store) => store.id)

        if (storeIds.length === 1) {
          this.setStore(nearbyStoresResponse.data[0])
        } else {
          await this.getPublicStoreProducts({
            by_distinct_product: true,
            by_store: storeIds,
          })
          this.has_store = false
          this.selectedStore = null
          await this.getCategories({
            by_store: storeIds,
          })
        }

        localStorage.setItem("delivery_option", this.deliveryOption)
        this.setNearbyStores(nearbyStoresResponse)
        this.setCurrentDeliveryOption(this.deliveryOption)
      } catch (e) {
        this.selectedProduct = null
        this.deliveryOption = null
        this.setCurrentDeliveryOption(null)
      } finally {
        this.$bvModal.hide("delivery-options-modal")
        this.$swal.close()
      }
    },

    showInstallToast() {
      const h = this.$createElement

      const $rejectButton = h(
        "b-button",
        {
          props: {
            variant: "ghost",
            size: "sm",
          },
          on: {
            click: () => {
              this.$bvToast.hide(this.installToastId)
            },
          },
        },
        "Cancelar"
      )

      const $installButton = h(
        "b-button",
        {
          props: {
            variant: "primary",
            size: "sm",
          },
          class: "ml-1",
          on: {
            click: () => {
              this.installApp()
            },
          },
        },
        "Instalar"
      )

      this.$bvToast.toast([$rejectButton, $installButton], {
        id: this.installToastId,
        title: "Deseas instalar la aplicación a tu pantalla de inicio?",
        variant: "info",
        solid: true,
        toaster: "b-toaster-bottom-right",
        noAutoHide: true,
      })
    },

    isPostalCodeValid() {
      return (
        (this.postalCode && this.postalCode !== "null") ||
        this.currentDeliveryOption === "not_applicable"
      )
    },

    async fetchInitialData(showLoading = true) {
      if (showLoading) {
        this.showLoading()
      }
      try {
        if (!this.isMarketplace) {
          await this.fetchLandingPage(this.$route.params.slug)
        }

        await this.getStoretypes()

        if (this.store_id || this.token) {
          await this.initialFetchWithStoreId(this.store_id, this.token)
          return
        }

        this.setCurrentDeliveryOption(localStorage.getItem("delivery_option"))

        if (this.isPostalCodeValid()) {
          await this.initialFetchWithPostalCode()
        } else {
          await this.initialFetchWithoutPostalCode()
        }
      } catch (e) {
        console.error(e)
        this.handleFetchError(e.response.data, "Error al cargar la página")
        this.resetAll()
        this.$router.push({ name: "home" })
      } finally {
        this.hideLoading()
      }
    },

    async initialFetchWithStoreId(storeId, token) {
      const res = await this.fetchShortStore(storeId)
      this.setStore(res)
      this.setCurrentStore(res)
      this.setCurrentDeliveryOption(res.delivery_option)
    },

    async initialFetchWithPostalCode() {
      const nearbyStoresResponse = await this.getNearbyStores({
        by_delivery_option: this.currentDeliveryOption,
        by_postal_code: this.postalCode,
      })

      const storeIds = nearbyStoresResponse.data.map((store) => store.id)

      if (storeIds.length === 1) {
        this.setNearbyStores(nearbyStoresResponse)
        this.setStore(nearbyStoresResponse.data[0])
        return
      }

      const publicStoreProducts = await this.getPublicStoreProducts({
        by_distinct_product: true,
        by_store: storeIds,
        by_store_type: this.filters.storeTypes || null,
      })
      this.setStoreProducts(publicStoreProducts)

      await this.getCategories({
        by_store: storeIds,
      })
      this.setNearbyStores(nearbyStoresResponse)
    },
    async initialFetchWithoutPostalCode() {
      this.postalCode = null
      const res = await this.getPublicStoreProducts({
        by_distinct_product: true,
        by_store_type: this.filters.storeTypes || null,
      })
      this.setStoreProducts(res)
      await this.getCategories()
    },

    async getStores(filters) {
      const landingPage = await this.realLandingPage
      const establishmentId = landingPage?.establishment_id

      const res = await this.fetchShortStores({
        by_category: "online",
        by_exclude_store: this.fromStoreId,
        with_store_products: true,
        by_marketplace: this.isMarketplace ? "true" : null,
        by_establishment: establishmentId,
        meta: { pagination: { per_page: 10000 } },
        ...filters,
      })

      if (res.data.length === 0) {
        throw new Error("No stores found")
      }

      return res
    },

    async getNearbyStores(filters) {
      try {
        const landingPage = await this.realLandingPage
        const establishmentId = landingPage?.establishment_id

        const res = await this.fetchShortStores({
          by_category: "online",
          by_exclude_store: this.fromStoreId,
          with_store_products: true,
          by_marketplace: this.isMarketplace ? "true" : null,
          by_establishment: establishmentId,
          meta: { pagination: { per_page: 10000 } },
          ...filters,
        })

        if (res.data.length === 0) {
          throw new Error("No nearby stores found")
        }

        return res
      } catch (e) {
        this.showErrorToast(
          "Error",
          "Lo sentimos, no encontramos tiendas cercanas en tu código postal actual"
        )
        this.selectedProduct = null

        await this.getPublicStoreProducts({
          by_distinct_product: true,
        })
        await this.getCategories()
        this.has_store = false

        this.resetAll()
        throw e
      }
    },
    async getStoretypes() {
      try {
        const storeTypes = await this.fetchStoreTypes()
        this.storeTypes = storeTypes
        if (this.storeType) {
          this.filters.storeTypes = [this.storeType]
        }
      } catch (e) {
        this.handleFetchError(
          e.response.data,
          "Error al cargar los tipos de tiendas"
        )
      }
    },

    async getPublicStoreProducts(
      filters = {},
      { page = 1, setStoreProducts = true } = {}
    ) {
      this.loadingProducts = true

      try {
        const landingPage = await this.realLandingPage
        const establishmentId = landingPage?.establishment_id

        const res = await this.fetchPublicStoreProducts({
          by_store_category: "online",
          by_store_marketplace: this.isMarketplace ? true : null,
          by_exclude_store: this.fromStoreId,
          by_establishment: establishmentId,
          meta: {
            pagination: { page },
          },
          ...filters,
        })

        if (setStoreProducts) {
          this.setStoreProducts(res)
        }

        return res
      } finally {
        this.loadingProducts = false
      }
    },
    async getCategories(filters) {
      try {
        const landingPage = await this.realLandingPage
        const establishmentId = landingPage?.establishment_id

        const res = await this.fetchCategories({
          by_active_status: true,
          by_establishment: establishmentId,
          meta: { pagination: { page: 1, per_page: 10000 } },
          ...filters,
        })

        this.categories = res.data

        return res
      } catch (e) {
        this.handleFetchError(
          e.response.data,
          "Error al cargar categorías de la tienda"
        )
      }
    },

    async handlePostalCodeSubmit(newPostalCode) {
      try {
        this.postalCode = newPostalCode
        this.loadingSwal()

        const filters = {
          by_postal_code: this.postalCode,
          by_delivery_option: this.deliveryOption,
        }

        const nearbyStoresResponse = await this.getStores(filters)

        this.setNearbyStores(nearbyStoresResponse.data)

        this.emptyCart()
        this.setStore(null)
        this.setCurrentStore(null)

        const storeIds = nearbyStoresResponse.data.map((store) => store.id)

        this.clearFilters()

        if (this.selectedProduct) {
          await this.getPublicStoreProducts(
            {
              by_distinct_product: true,
              by_store: storeIds,
            },
            { page: 1, setStoreProducts: false }
          )

          const storesResponse = await this.getStores({
            by_product: this.selectedProduct.id,
            ...filters,
          })

          const uniqueStores = filterUniqueById(storesResponse.data)

          this.setAvailableStores(uniqueStores)
          this.$bvModal.show("store-product-options-modal")
        } else if (nearbyStoresResponse.data.length === 1) {
          this.setStore(nearbyStoresResponse.data[0])
        } else {
          await this.getPublicStoreProducts({
            by_distinct_product: true,
            by_store: storeIds,
          })
          this.has_store = false
          this.selectedStore = null

          await this.getCategories({
            by_store: storeIds,
          })
        }

        this.setCurrentDeliveryOption(this.deliveryOption)
        localStorage.setItem("postal_code", newPostalCode)
        localStorage.setItem("delivery_option", this.deliveryOption)
      } catch (e) {
        this.showErrorToast(
          "Error",
          "Lo sentimos, no encontramos tiendas cercanas en tu código postal actual"
        )

        await this.getPublicStoreProducts({
          by_distinct_product: true,
        })
        await this.getCategories()

        this.resetAll()
      } finally {
        this.$bvModal.hide("ask-postal-code-modal")
        this.$swal.close()
      }
    },

    hideLoading() {
      if (this.appLoading) {
        this.appLoading.style.display = "none"
      }
      this.loading = false
    },
    showLoading() {
      if (this.appLoading) {
        this.appLoading.style.display = "flex"
      }
      this.loading = true
    },

    resetAll() {
      this.has_store = false
      this.postalCode = null

      this.previousStore = null
      this.previousDeliveryOption = null
      this.selectedStoreProduct = null

      localStorage.removeItem("postal_code")
      localStorage.removeItem("delivery_option")

      this.selectedStore = null
      this.setCurrentDeliveryOption(null)
      this.selectedProduct = null
      this.deliveryOption = null

      this.emptyCart()
      this.setCurrentStore(null)
      this.setAvailableStores([])
      this.setStore(null)
      this.setNearbyStores([])
    },

    removePostalCode() {
      this.resetAll()
      this.fetchInitialData(false)
    },
    resetStates() {
      this.setEstablishment(null)
      this.setCurrentDeliveryOption(null)
      this.emptyCart()
      this.setStore(null)
      this.has_store = false
      this.setCurrentStore(null)
      this.selectedStore = null
      this.selectedProduct = null
    },

    goBack() {
      this.$bvModal.show("delivery-options-modal")
    },

    handleChangePostalCode() {
      this.selectedProduct = null
      this.$bvModal.show("delivery-options-modal")
    },

    handleStoreProductSelection(product, storeProduct) {
      this.selectedStoreProduct = {
        ...storeProduct,
        product_attributes: {
          ...product,
          store_products: undefined,
        },
      }

      this.$bvModal.show("store-product-details-modal")
    },

    handleSearch(query) {
      this.filters.q = query
    },
    setQuery(query) {
      this.filters.q = query
      this.handleOnChangeFilters()
    },

    shouldShowDeliveryOptionsModal() {
      return (
        !this.isPostalCodeValid() &&
        !this.store_id &&
        !this.token &&
        this.currentDeliveryOption !== "not_applicable" &&
        !this.store
      )
    },

    async handleAddToCart({
      product,
      storeProduct,
      bestPromotion = null,
      units = 1,
      uniqueId,
      skipReferences = false,
    }) {
      this.selectedProduct = product

      if (this.shouldShowDeliveryOptionsModal()) {
        this.$bvModal.show("delivery-options-modal")
        return
      }

      if (!this.store) {
        const res = await this.getStores({
          by_postal_code:
            this.currentDeliveryOption === "not_applicable"
              ? null
              : this.postalCode,
          by_delivery_option: this.currentDeliveryOption,
          by_product: product.id,
        })

        if (res.data.length === 0) {
          this.showErrorToast(
            "Error",
            "Lo sentimos, no encontramos tiendas cercanas en tu código postal actual"
          )
          this.selectedProduct = null
          return
        }

        const uniqueStores = filterUniqueById(res.data)
        this.setAvailableStores(uniqueStores)

        this.clearFilters()

        this.$bvModal.show("store-product-options-modal")

        return
      } else {
        const newStoreProduct = {
          ...storeProduct,
          product_attributes: {
            ...product,
            store_products: undefined,
          },
          store_attributes: {
            ...this.store,
          },
        }

        if (this.storeHasReferences && !skipReferences) {
          this.$bvModal.show(`book-store-modal-${storeProduct.id}-${uniqueId}`)
          return
        }

        if (!bestPromotion || newStoreProduct.is_bundle) {
          this.addCartItem({
            ...newStoreProduct,
            units,
          })
          return
        }

        const { needs_to_buy } = bestPromotion

        if (Number(newStoreProduct.units) >= needs_to_buy) {
          this.addCartItem({
            ...newStoreProduct,
            units: parseInt(newStoreProduct.units),
          })
          return
        }

        const bestPromotionText = beautyPromotion(bestPromotion)

        this.$swal({
          title: "Producto con oferta",
          text: `¿Deseas aplicar la oferta de ${bestPromotionText}?`,
          icon: "warning",
          showDenyButton: true,
          confirmButtonText: "Si, aplicar",
          denyButtonText: "No",
          customClass: {
            confirmButton: "btn btn-primary",
            denyButton: "btn btn-outline-danger mr-1",
          },
          reverseButtons: true,
          buttonsStyling: false,
        }).then((result) => {
          if (result.isConfirmed) {
            this.addCartItem({
              ...newStoreProduct,
              units: parseInt(needs_to_buy),
            })
          } else if (result.isDenied) {
            this.addCartItem(newStoreProduct)
          }
        })
      }

      this.selectedProduct = null
    },

    async getGroupedStoreProducts(filters, page = 1) {
      try {
        this.loadingProducts = true
        const res = await this.fetchGroupedStoreProducts({
          by_store: this.store.id,
          by_active_status: true,
          by_category: this.filters.categories || null,
          by_nutritional_info: this.filters.nutriScore || null,
          by_token: this.token || null,
          by_name: this.filters.q || null,
          by_store_category: "online",
          by_exclude_store: this.fromStoreId,
          meta: {
            pagination: {
              page,
            },
          },
          ...filters,
        })

        this.has_store = true
        this.setStoreProducts(res)

        return res
      } finally {
        this.loadingProducts = false
      }
    },

    async handlePagination({ page, per_page }) {
      if (this.selectedStore) {
        this.getGroupedStoreProducts({}, page)
      } else {
        await this.getPublicStoreProducts(
          {
            by_distinct_product: true,
            by_category: this.filters.categories || null,
            by_nutritional_info: this.filters.nutriScore || null,
            by_name: this.filters.q || null,
            by_store: this.nearbyStoreIds,
            by_store_type: this.filters.storeTypes || null,
          },
          { page, setStoreProducts: true }
        )
      }
      window.scrollTo(0, 300)
    },
    handleStoreChange(event) {
      if (this.selectedStore === event) return

      const store = this.nearbyStores.find((store) => store.id === event)

      if (!this.previousStore || !this.cart.length) {
        this.selectedStore = event
        this.previousStore = this.selectedStore
        this.setStore(store)
        return
      }

      this.$swal({
        title: "Cambiando de tienda",
        text: "¿Está seguro de que quiere cambiar de tienda? Tu carro de compras no se guardará.",
        icon: "warning",
        showCancelButton: true,
        allowOutsideClick: false,
        confirmButtonText: "Cambiar",
        cancelButtonText: "No",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-secondary mr-1",
        },
        reverseButtons: true,
        buttonsStyling: false,
      }).then((res) => {
        if (res.isConfirmed) {
          this.selectedStore = event
          this.previousStore = this.selectedStore
          this.setStore(store)
        } else {
          this.$nextTick(() => {
            this.selectedStore = this.previousStore
            this.$refs.storeSelect.$el.value = this.previousStore
          })
        }
      })
    },
  },
  computed: {
    ...mapGetters("landingPage", [
      "store",
      "landingPage",
      "establishment",
      "currentDeliveryOption",
    ]),
    ...mapGetters("storeProducts", [
      "pagination",
      "storeProducts",
      "availableStores",
    ]),
    ...mapGetters("stores", ["nearbyStores", "currentStore"]),
    ...mapGetters("cart", ["cart"]),

    async realLandingPage() {
      if (this.landingPage) {
        return this.landingPage
      }

      if (!this.isMarketplace) {
        await this.fetchLandingPage(this.$route.params.slug)
        console.log(this.landingPage)
        return this.landingPage
      }

      return null
    },

    nearbyStoreIds() {
      if (!this.nearbyStores) {
        return []
      }

      return this.nearbyStores.map((store) => store.id)
    },

    storeOptions() {
      if (!this.nearbyStores) {
        return []
      }

      return this.nearbyStores.map((store) => ({
        text: store.name,
        value: store.id,
      }))
    },
  },
}
</script>

<style lang="scss">
.modal-content {
  background-color: #1b243d;

  .modal-body {
    padding: 0rem;
    backdrop-filter: #1b243d;
    z-index: 100;
  }
}

@import "~@core/scss/base/pages/app-ecommerce.scss";
</style>

<style lang="scss" scoped>
.landing__banner {
  margin-top: 10rem;

  @media (min-width: 768px) {
    margin-top: 10rem;
  }

  @media (min-width: 992px) {
    margin-top: 12em;
  }
}

.sidebar__container {
  position: absolute;
  top: 0;
  left: 0;
}

.main__contaner {
  padding-bottom: 4rem;
}

.carousel-wrapper {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.snap__container {
  position: relative;
  width: 100%;
  scroll-behavior: smooth;
  transition: scroll-left 0.5s;
  display: flex;
  overflow-x: auto;

  scroll-snap-type: x mandatory;

  @media (min-width: 768px) {
    overflow-x: hidden;
  }

  .snap__item {
    flex: 0 0 auto;
    width: 100%;
    scroll-snap-align: center;
    object-fit: cover;
    object-position: center;
    max-height: 250px;

    @media (min-width: 768px) {
      max-height: 400px;
    }
  }
}

.store-select {
  width: 100%;
  z-index: 1000;
}

.snap__nav {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  display: none;
  align-items: center;
  justify-content: center;
  width: 50px;
  background-color: #ccc;
  border-radius: 50%;
  height: 50px;
  cursor: pointer;
  user-select: none;

  &:hover {
    background-color: #aaa;
  }

  @media (min-width: 768px) {
    display: flex;
  }
}

#prevBtn {
  left: 10px;
}

#nextBtn {
  right: 10px;
}

.spinner__container {
  padding: 2rem;
}

.products__container {
  display: flex;
  justify-content: space-between;
}

.mt-10 {
  margin-top: 8.4rem;
}

.searchbar-container {
  display: flex;
  gap: 0.5rem;
  align-items: center;
  flex-wrap: wrap;
}

@media (min-width: 768px) {
  .searchbar-container {
    gap: 2rem;
  }
}

@supports (animation-timeline: view()) {
  @keyframes adjust-searchbar-bg {
    to {
      padding: 10px;
      backdrop-filter: blur(10px);
    }
  }
  .searchbar-container {
    animation: adjust-searchbar-bg linear both;
    animation-timeline: view();
    animation-range: contain;
    padding: 0px;
    background-color: transparent;
    top: 60px;
    z-index: 10;
  }
}
</style>
