var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.currentStore)?_c('div',[(_vm.currentStore.accepted_payment_types.length > 0)?_c('div',{staticClass:"payments__grid"},[(_vm.showCodiButton)?_c('PaymentButton',{directives:[{name:"b-modal",rawName:"v-b-modal.complete-codi-payment",modifiers:{"complete-codi-payment":true}}],attrs:{"dataTestId":"codi-button","buttonText":"Codi","imageSrc":require('@/assets/images/logo/CODI.svg')}}):_vm._e(),(_vm.showCashButton)?_c('PaymentButton',{attrs:{"dataTestId":"cash-button","buttonText":"Efectivo","buttonVariant":_vm.selectedPaymentMethod === 'cash' ? 'primary' : 'outline-primary',"imageSrc":require('@/assets/images/logo/CASH.svg')},on:{"click":function($event){return _vm.handleSelectedPaymentMethod('cash')}}}):_vm._e(),(_vm.showBankCardButton)?_c('PaymentButton',{attrs:{"dataTestId":"bank-card-button","buttonText":"Tarjeta","buttonVariant":_vm.selectedPaymentMethod === 'bankcard' ? 'primary' : 'outline-primary',"imageSrc":require('@/assets/images/logo/bank_card.png')},on:{"click":function($event){return _vm.handleSelectedPaymentMethod('bankcard')}}}):_vm._e(),(_vm.showRewardPointsButton)?_c('PaymentButton',{attrs:{"dataTestId":"reward-points-button","buttonText":"Puntos de recompensa","buttonVariant":_vm.selectedPaymentMethod === 'reward_points'
          ? 'primary'
          : 'outline-primary',"imageSrc":require('@/assets/images/logo/reward_points.png')},on:{"click":function($event){return _vm.handleSelectedPaymentMethod('reward_points')}}}):_vm._e(),(_vm.showBankDepositButton)?_c('PaymentButton',{attrs:{"dataTestId":"bank-deposit-button","buttonText":"Transferencia/depósito","buttonVariant":_vm.selectedPaymentMethod === 'bank_deposit'
          ? 'primary'
          : 'outline-primary',"imageSrc":require('@/assets/images/logo/bank_transfer.png')},on:{"click":function($event){return _vm.handleSelectedPaymentMethod('bank_deposit')}}}):_vm._e()],1):_vm._e(),(
      _vm.currentStore.accepted_payment_types.length === 0 ||
      (_vm.currentStore.accepted_payment_types[0] === 'bankcard' && !_vm.storeAccount)
    )?_c('div',[_c('b-alert',{staticClass:"mt-2",attrs:{"show":"","variant":"warning","title":("No se han configurado métodos de pago para " + (_vm.currentStore.name))}},[_c('p',[_vm._v(" Por favor, contacta a la tienda para que configure los métodos de pago. ")])])],1):_vm._e(),_c('codi-payment-modal',{attrs:{"order":_vm.order,"loading":_vm.loading,"completeOnlineOrder":_vm.completeCheckout,"handleCancelCodiModal":_vm.handleCancelCodiModal}}),_c('codi-info-modal')],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }