<template>
  <b-modal
    :id="id"
    ref="resumenDeCompraModal"
    title="Resumen de compra"
    centered
    no-close-on-backdrop
    no-close-on-esc
    hide-footer
  >
    <div class="p-2" v-if="prevOrder">
      <b-card-text class="text-center">
        <h5 class="text-primary">¡Gracias por tu compra!</h5>

        <p>
          {{ purchaseText }}
        </p>

        <div v-if="prevPaymentMethod === 'bank_deposit' && fundingInstructions">
          <h6 class="text-2xl pb-1 pt-50">Instrucciones de pago</h6>
          <p><b>CLABE:</b> {{ fundingInstructions.clabe }}</p>
          <p><b>Nombre del banco:</b> {{ fundingInstructions.bank_name }}</p>
          <p>
            <b>Nombre del titular:</b>
            {{ fundingInstructions.account_holder_name }}
          </p>
        </div>
      </b-card-text>

      <b-button
        v-ripple
        variant="primary"
        class="w-100 mt-2"
        :to="{ name: 'order-view', params: { id: prevOrder.id } }"
      >
        Ver detalles
      </b-button>
    </div>
  </b-modal>
</template>

<script>
export default {
  props: {
    id: {
      type: String,
      default: "purchase-overview-modal",
    },
    purchaseText: {
      type: String,
      default: "",
    },
    prevOrder: {
      type: Object,
      default: null,
    },
    prevPaymentMethod: {
      type: String,
      default: "",
    },
    fundingInstructions: {
      type: Object,
      default: null,
    },
  },
}
</script>
