<template>
  <div>
    <codi-purchase-modal
      :codi-qrcode="codiQrcode"
      :codi-response-type="codiResponseType"
    />

    <purchase-overview-modal
      :purchase-text="purchaseText"
      :prev-order="prevOrder"
      :prev-payment-method="prevPaymentMethod"
      :funding-instructions="fundingInstructions"
    />

    <b-button variant="ghost" @click="visibleSidebar = true">
      <feather-icon
        icon="ShoppingCartIcon"
        size="26"
        class="text-primary"
        :badge="cartProductsCount"
      />
    </b-button>
    <b-sidebar
      id="cart-sidebar"
      ref="cartSidebar"
      title="Carrito"
      right
      size="lg"
      width="40em"
      backdrop
      shadow
      no-enforce-focus
      sidebar-class="cart__sidebar"
      body-class="overflow-auto pb-2 d-flex flex-column sidebar__body"
      :visible="visibleSidebar"
      @hidden="handleSidebarHidden"
      @shown="handleSidebarVisible"
    >
      <div class="cart__container">
        <form-wizard
          ref="wizard"
          color="#7367F0"
          :title="null"
          :subtitle="null"
          on-text="Confirmar"
          finish-button-text="Confirmar"
          next-button-text="Siguiente"
          back-button-text="Regresar"
          wizardClass="vue-form-wizard p-1"
          bodyClass="h-100"
          class="py-2"
          @on-complete="handleCompleteCheckout"
        >
          <tab-content
            title="Carrito"
            class="wizard-tab"
            :before-change="validateCart"
          >
            <div class="overflow-auto grow">
              <Cart :products="storeProducts" />
            </div>
            <OrderInfoOnline class="pt-1 px-1" />
          </tab-content>

          <tab-content
            :show-step="!currentUser"
            title="Datos de contacto"
            :before-change="validateContactForm"
          >
            <div class="overflow-auto">
              <div class="pt-2 d-flex flex-column p-1 send-info">
                <h6 class="text-2xl">Datos de contacto</h6>
                <validation-observer ref="contactObserver">
                  <b-form-group label="Nombre" label-for="name" name="Nombre">
                    <validation-provider
                      rules="required"
                      name="Nombre"
                      v-slot="{ errors, valid }"
                    >
                      <b-form-input
                        name="Nombre"
                        v-model="localName"
                        placeholder="Nombre"
                        :state="errors.length > 0 ? false : valid ? true : null"
                      />
                      <b-form-invalid-feedback v-if="errors.length">
                        {{ errors[0] }}
                      </b-form-invalid-feedback>
                    </validation-provider>
                  </b-form-group>
                  <b-form-group
                    label="Correo electrónico"
                    label-for="email"
                    name="Correo electrónico"
                  >
                    <validation-provider
                      rules="required|email"
                      name="Correo electrónico"
                      v-slot="{ errors, valid }"
                    >
                      <b-form-input
                        name="Correo electrónico"
                        v-model="localEmail"
                        placeholder="Correo electrónico"
                        :state="errors.length > 0 ? false : valid ? true : null"
                      />
                      <b-form-invalid-feedback v-if="errors.length">
                        {{ errors[0] }}
                      </b-form-invalid-feedback>
                    </validation-provider>
                  </b-form-group>
                  <b-form-group
                    label="Número de teléfono"
                    label-for="phone_number"
                    name="Número de teléfono"
                  >
                    <validation-provider
                      rules="required|phone_number"
                      name="Número de teléfono"
                      v-slot="{ errors, valid }"
                    >
                      <b-form-input
                        id="phone_number"
                        name="Número de teléfono"
                        v-model="localPhoneNumber"
                        placeholder="Número de teléfono"
                        :state="errors.length > 0 ? false : valid ? true : null"
                      />
                      <b-form-invalid-feedback v-if="errors.length">
                        {{ errors[0] }}
                      </b-form-invalid-feedback>
                    </validation-provider>
                  </b-form-group>
                </validation-observer>

                <div>
                  <p>Estos datos serán utilizados para rastrear tu pedido.</p>
                  <p>
                    Al continuar con la compra, aceptas los
                    <b-link to="/terms_of_use" target="_blank"
                      >términos y condiciones</b-link
                    >
                    de la tienda.
                  </p>
                </div>
              </div>
            </div>
          </tab-content>

          <tab-content
            :show-step="currentDeliveryOption === 'delivery'"
            title="Dirección de envío"
            :before-change="validateAddressForm"
          >
            <div class="overflow-auto">
              <div class="d-flex flex-column p-50 send-info">
                <h6 class="text-2xl">Dirección de envío</h6>

                <b-button
                  v-if="currentUser && currentUser.delivery_addresses"
                  @click="$bvModal.show('delivery-addresses-modal')"
                  variant="outline-primary"
                  size="sm"
                  class="d-flex align-items-center mt-50"
                >
                  <feather-icon icon="MapPinIcon" size="16" class="mr-50" />
                  <span>Selecciona una dirección existente</span>
                </b-button>
                <div class="py-2" v-if="currentStore">
                  <gmap-autocomplete
                    ref="googleAddress"
                    class="mb-2"
                    placeholder="Buscar dirección"
                    :componentRestrictions="{ country: 'mx' }"
                    autocomplete="off"
                    @place_changed="setAddressForEdit($event)"
                  />
                  <GmapMap
                    v-b-tooltip.hover.bottomleft
                    title="Doble click para seleccionar dirección"
                    @click="setMarker($event)"
                    :center="center"
                    :zoom="zoom"
                    map-type-id="roadmap"
                    :options="mapOptions"
                    :style="`width: 100%; height: 300px`"
                  >
                    <GmapMarker
                      :position="marker"
                      :clickable="true"
                      :draggable="false"
                    />
                  </GmapMap>

                  <small class="text-600 d-block"
                    >La informacion del mapa es adecuada</small
                  >
                  <small class="text-600 d-block"
                    >Si no encuentras tu calle, selecciona una direccion cercana
                    y modifica la informacion</small
                  >
                </div>

                <validation-observer ref="addressObserver">
                  <b-form-group label="Nombre" label-for="name" name="Nombre">
                    <validation-provider
                      rules="required"
                      name="Nombre"
                      v-slot="{ errors, valid }"
                    >
                      <b-form-input
                        id="name"
                        name="Nombre"
                        v-model="localAddress.name"
                        placeholder="Nombre"
                        :state="errors.length > 0 ? false : valid ? true : null"
                      />
                      <b-form-invalid-feedback v-if="errors.length">
                        {{ errors[0] }}
                      </b-form-invalid-feedback>
                    </validation-provider>
                  </b-form-group>
                  <b-form-group
                    v-if="currentUser"
                    label="Telefono"
                    label-for="phone_number"
                    name="Telefono"
                  >
                    <validation-provider
                      rules="required|phone_number"
                      name="Telefono"
                      v-slot="{ errors, valid }"
                    >
                      <b-form-input
                        id="phone_number"
                        name="Telefono"
                        v-model="localAddress.cel_number"
                        placeholder="Telefono"
                        :maxlength="10"
                        :state="errors.length > 0 ? false : valid ? true : null"
                      />
                      <b-form-invalid-feedback v-if="errors.length">
                        {{ errors[0] }}
                      </b-form-invalid-feedback>
                    </validation-provider>
                  </b-form-group>
                  <b-form-group
                    v-if="storeReferences.includes('delivery_date')"
                    label="Fecha de entrega"
                    label-for="delivery_date"
                  >
                    <validation-provider
                      rules="required"
                      name="Fecha de entrega"
                      v-slot="{ errors, valid }"
                    >
                      <b-form-datepicker
                        id="delivery_date"
                        name="Fecha de entrega"
                        v-model="localAddress.delivery_date"
                        placeholder="Fecha de entrega"
                        locale="es"
                        :min="minDate"
                        :max="maxDate"
                        :state="errors.length > 0 ? false : valid ? true : null"
                      />
                      <b-form-invalid-feedback v-if="errors.length">
                        {{ errors[0] }}
                      </b-form-invalid-feedback>
                    </validation-provider>
                  </b-form-group>
                  <b-form-group
                    label="Código postal"
                    label-for="postal_code"
                    name="Código postal"
                  >
                    <validation-provider
                      rules="required|zip_code"
                      name="Código postal"
                      v-slot="{ errors, valid }"
                    >
                      <b-form-input
                        id="postal_code"
                        name="Código postal"
                        v-model="localAddress.postal_code"
                        placeholder="Código postal"
                        :state="errors.length > 0 ? false : valid ? true : null"
                      />
                      <b-form-invalid-feedback v-if="errors.length">
                        {{ errors[0] }}
                      </b-form-invalid-feedback>
                    </validation-provider>
                  </b-form-group>
                  <b-form-group
                    label="Colonia"
                    label-for="suburb"
                    name="Colonia"
                  >
                    <validation-provider
                      rules="required"
                      name="Colonia"
                      v-slot="{ errors, valid }"
                    >
                      <b-form-input
                        id="suburb"
                        name="Colonia"
                        v-model="localAddress.suburb"
                        placeholder="Colonia"
                        :state="errors.length > 0 ? false : valid ? true : null"
                      />
                      <b-form-invalid-feedback v-if="errors.length">
                        {{ errors[0] }}
                      </b-form-invalid-feedback>
                    </validation-provider>
                  </b-form-group>
                  <b-form-group label="Calle" label-for="street" name="Calle">
                    <validation-provider
                      rules="required"
                      name="Calle"
                      v-slot="{ errors, valid }"
                    >
                      <b-form-input
                        id="street"
                        name="Calle"
                        v-model="localAddress.street"
                        placeholder="Calle"
                        :state="errors.length > 0 ? false : valid ? true : null"
                      />
                      <b-form-invalid-feedback v-if="errors.length">
                        {{ errors[0] }}
                      </b-form-invalid-feedback>
                    </validation-provider>
                  </b-form-group>
                  <b-form-group
                    label="Numero exterior"
                    label-for="ext_number"
                    name="Numero exterior"
                  >
                    <validation-provider
                      rules="required"
                      name="Numero exterior"
                      v-slot="{ errors, valid }"
                    >
                      <b-form-input
                        id="ext_number"
                        name="Numero exterior"
                        v-model="localAddress.ext_number"
                        placeholder="Numero exterior"
                        :state="errors.length > 0 ? false : valid ? true : null"
                      />
                      <b-form-invalid-feedback v-if="errors.length">
                        {{ errors[0] }}
                      </b-form-invalid-feedback>
                    </validation-provider>
                  </b-form-group>
                  <b-form-group
                    label="Numero interior (opcional)"
                    label-for="int_number"
                    name="Numero interior"
                  >
                    <validation-provider
                      name="Numero interior"
                      v-slot="{ errors, valid }"
                    >
                      <b-form-input
                        id="int_number"
                        name="Numero interior"
                        v-model="localAddress.int_number"
                        placeholder="Numero interior"
                      />
                      <b-form-invalid-feedback v-if="errors.length">
                        {{ errors[0] }}
                      </b-form-invalid-feedback>
                    </validation-provider>
                  </b-form-group>

                  <b-form-group label="Ciudad" label-for="city" name="Ciudad">
                    <validation-provider
                      rules="required"
                      name="Ciudad"
                      v-slot="{ errors, valid }"
                    >
                      <b-form-input
                        id="city"
                        name="Ciudad"
                        v-model="localAddress.city"
                        placeholder="Ciudad"
                        :state="errors.length > 0 ? false : valid ? true : null"
                      />

                      <b-form-invalid-feedback v-if="errors.length">
                        {{ errors[0] }}
                      </b-form-invalid-feedback>
                    </validation-provider>
                  </b-form-group>

                  <b-form-group label="Estado" label-for="state" name="Estado">
                    <b-form-input
                      id="state"
                      name="Estado"
                      v-model="localAddress.state"
                      placeholder="Estado"
                      disabled
                    />
                  </b-form-group>
                  <small class="text-muted mt-1">
                    * Los datos de contacto seran utilizados para rastrear el
                    pedido, crear una cuenta y comunicarnos contigo en caso de
                    ser necesario.
                  </small>

                  <small class="text-muted mt-1">
                    Al continuar con la compra, aceptas los
                    <b-link to="/terms_of_use" target="_blank"
                      >términos y condiciones</b-link
                    >
                    de la tienda.
                  </small>
                </validation-observer>
              </div>
            </div>
          </tab-content>

          <tab-content title="Resumen de pedido">
            <div class="overflow-auto p-50 h-100 d-flex flex-column">
              <h6 class="text-2xl pb-1">Resumen de pedido</h6>

              <h6>{{ currentStore.name }}</h6>

              <p class="my-1">
                Tipo de pedido:
                <b>{{ currentDeliveryOption | deliveryOption }}</b>
              </p>

              <p v-if="currentDeliveryOption === 'delivery'">
                La entrega es en:
              </p>
              <p v-if="currentDeliveryOption === 'pickup'">Recoger en:</p>

              <div
                v-if="
                  currentDeliveryOption === 'pickup' &&
                  currentStore.address_attributes
                "
              >
                <p class="pb-1">
                  <span v-if="currentStore.address_attributes.street">
                    {{ currentStore.address_attributes.street }}
                  </span>
                  <span v-if="currentStore.address_attributes.ext_number">
                    {{ currentStore.address_attributes.ext_number }}
                  </span>
                  <span v-if="currentStore.address_attributes.int_number">
                    (num. int {{ currentStore.address_attributes.int_number }} )
                  </span>
                  <span v-if="currentStore.address_attributes.postal_code">
                    CP: {{ currentStore.address_attributes.postal_code }}
                  </span>
                  <span v-if="currentStore.address_attributes.city">
                    {{ currentStore.address_attributes.city }}
                  </span>
                  <span v-if="currentStore.address_attributes.state">
                    {{ currentStore.address_attributes.state }}
                  </span>
                  <span v-if="currentStore.address_attributes.country">
                    {{ currentStore.address_attributes.country }}
                  </span>
                </p>
                <GoogleMap
                  v-if="
                    currentStore &&
                    currentStore.address_attributes &&
                    currentStore.address_attributes.lat &&
                    currentStore.address_attributes.lng
                  "
                  :lat="parseFloat(currentStore.address_attributes.lat)"
                  :lng="parseFloat(currentStore.address_attributes.lng)"
                />
              </div>
              <div
                v-if="
                  currentDeliveryOption === 'delivery' &&
                  currentStore.address_attributes
                "
              >
                <p class="pb-1">
                  <span v-if="address.street">
                    {{ address.street }}
                  </span>
                  <span v-if="address.ext_number">
                    {{ address.ext_number }}
                  </span>
                  <span v-if="address.int_number">
                    (num. int {{ address.int_number }} )
                  </span>
                  <span v-if="address.postal_code">
                    CP: {{ address.postal_code }}
                  </span>
                  <span v-if="address.city">
                    {{ currentStore.address_attributes.city }}
                  </span>
                  <span v-if="address.state">
                    {{ currentStore.address_attributes.state }}
                  </span>
                  <span v-if="address.country">
                    {{ currentStore.address_attributes.country }}
                  </span>
                </p>
                <GoogleMap
                  v-if="order && address && address.lat && address.lng"
                  :lat="parseFloat(address.lat)"
                  :lng="parseFloat(address.lng)"
                />
              </div>
              <OrderInfoOnline class="pt-1 mt-auto" />
            </div>
          </tab-content>
          
          <tab-content
            title="Métodos de pago"
            class="p-50"
            :before-change="validatePaymentMethod"
          >
            <h6 class="text-2xl pb-1 pt-50">Métodos de pago</h6>
            <PaymentMethods
              :order="order"
              :loading="loading"
              :selectedPaymentMethod="selectedPaymentMethod"
              :completeCheckout="handleCompleteCheckout"
              :handleCancelCodiModal="handleCancelCodiModal"
              :handleSelectedPaymentMethod="handleSelectedPaymentMethod"
            />
          </tab-content>
        </form-wizard>
      </div>
    </b-sidebar>

    <delivery-addresses-modal
      v-if="currentUser && currentUser.delivery_addresses"
      :addresses="currentUser.delivery_addresses"
      :handleAddressSelection="handleAddressSelection"
      @setAddress="handleSetAddress"
    />
  </div>
</template>

<script>
// Directives
import { mapGetters } from "vuex"
import { getGoogleMapsAPI } from "gmap-vue"
import { ValidationObserver, ValidationProvider } from "vee-validate"

import TabContent from "@core/components/wizard/wizard-step.vue"
import FormWizard from "@core/components/wizard/form-wizard.vue"

// Components
import Cart from "@core/components/Cart.vue"
import GoogleMap from "../GoogleMap.vue"
import OrderInfoOnline from "@/views/pos/OrderInfoOnline.vue"

import PaymentMethods from "@core/components/checkout/PaymentMethods.vue"
import DeliveryAddressesModal from "@core/components/checkout/DeliveryAddressesModal.vue"
import CodiPurchaseModal from "@core/components/checkout/codi-purchase-modal.vue"
import PurchaseOverviewModal from "@core/components/checkout/purchase-overview-modal.vue"

// Mixins
import cartMixin from "@/@core/mixins/cartMixin"
import messagesMixin from "@/@core/mixins/messagesMixin"

export default {
  components: {
    Cart,
    GoogleMap,
    OrderInfoOnline,
    FormWizard,
    TabContent,
    CodiPurchaseModal,
    PurchaseOverviewModal,

    PaymentMethods,
    DeliveryAddressesModal,

    ValidationProvider,
    ValidationObserver,
  },
  mixins: [cartMixin, messagesMixin],
  data() {
    const now = new Date()
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate())

    const minDate = new Date(today)
    minDate.setDate(today.getDate() + 2)

    const maxDate = new Date(today)
    maxDate.setDate(today.getDate() + 14)

    return {
      minDate,
      maxDate,
      codiQrcode: null,
      codiType: null,
      userFound: null,
      prevOrder: null,
      prevPaymentMethod: "",
      selectedPaymentMethod: "",
      localName: "",
      localEmail: "",
      visibleSidebar: false,
      localPhoneNumber: "",
      activeStep: 0,
      localAddress: {
        name: "",
        cel_number: "",
        street: "",
        ext_number: "",
        int_number: "",
        suburb: "",
        city: "",
        state: "",
        country: "",
        postal_code: "",
        delivery_date: "",
      },

      mapOptions: {
        streetViewControl: false,
        fullscreenControl: false,
        restriction: {
          latLngBounds: {
            north: 32.718,
            south: 14.532,
            west: -118.503,
            east: -86.589,
          },
          strictBounds: false,
        },
      },
      purchaseText:
        "Tu pedido ha sido procesado exitosamente. Te hemos enviado un correo con los detalles de tu compra.",
      googleAddress: {},
      marker: {},
      center: { lat: 19.4326, lng: -99.1332 },
      zoom: 4,
    }
  },
  computed: {
    ...mapGetters("stores", ["currentStore", "storeReferences"]),
    ...mapGetters("storeProducts", ["storeProducts"]),
    ...mapGetters("landingPage", ["currentDeliveryOption"]),
    ...mapGetters("auth", ["currentUser"]),
    ...mapGetters("cart", ["cart", "cartProductsCount"]),
    ...mapGetters("stripe", ["fundingInstructions"]),

    codiResponseType() {
      return this.codiType
    },
  },

  watch: {
    localEmail() {
      this.address.email = this.localEmail
      this.order.email = this.localEmail
    },
    localPhoneNumber() {
      this.address.cel_number = this.localPhoneNumber
      this.order.cel_number = this.localPhoneNumber
    },
    localName() {
      this.address.name = this.localName
      this.order.name = this.localName
    },
  },
  methods: {
    validateCart() {
      if (this.cartProductsCount === 0) {
        this.errorToast("Error", "Porfavor agrega productos al carrito")
        return false
      }

      return true
    },

    validatePaymentMethod() {
      if (!this.selectedPaymentMethod) {
        this.errorToast("Error", "Porfavor selecciona un metodo de pago")
        return false
      }

      return true
    },

    validateContactForm() {
      return this.$refs.contactObserver.validate().then((success) => {
        if (!success) {
          this.errorToast("Error", "Porfavor completa el formulario")
          return false
        }

        this.findUserByEmailOrPhone({
          email: this.address.email,
          phone_number: this.address.cel_number,
        })
          .then((res) => {
            const { exists } = res

            if (!exists) return true

            this.warningToast(
              "Atencion",
              "El usuario ya existe, porfavor inicia sesión o recuperá tu cuenta"
            )
            this.$bvModal.show("login-modal")
            return false
          })
          .catch((e) => {
            this.errorToast("Error", "Ocurrio un error al buscar el usuario")
            return false
          })
      })
    },

    validateAddressForm() {
      return this.$refs.addressObserver.validate().then((success) => {
        if (!success) {
          this.errorToast("Error", "Porfavor completa el formulario")
          return false
        }

        this.handleSetAddress(this.localAddress)

        if (this.$refs.googleAddress.$el.children[0].value === "") {
          this.errorToast(
            "Error",
            "Porfavor selecciona una ubicación en el mapa"
          )
          return false
        }

        return true
      })
    },

    async handleCompleteCheckout(paymentType, selectedIntent) {
      try {
        if (
          !localStorage.getItem("userData") &&
          (this.selectedPaymentMethod === "reward_points" ||
            this.selectedPaymentMethod === "codi")
        ) {
          this.warningToast("Atencion", "Porfavor inicia sesión para continuar")
          this.$bvModal.show("login-modal")
          return
        }

        const res = await this.completeCheckout({
          paymentType: paymentType ?? this.selectedPaymentMethod,
          deliveryOption: this.currentDeliveryOption,
          codiPayment: selectedIntent,
        })

        const pendingOrder = res
        this.codiType = selectedIntent

        this.prevOrder = pendingOrder
        this.prevPaymentMethod = this.selectedPaymentMethod || paymentType
        this.order = {}
        this.address = {}
        this.localEmail = ""
        this.localPhoneNumber = ""
        this.localAddress = {}

        this.handleCoDiPayment(this.prevPaymentMethod, pendingOrder)
        this.handleStripePayment(this.selectedPaymentMethod, pendingOrder)

        await this.$refs.cartSidebar.hide()

        if (this.prevPaymentMethod === "codi") {
          this.purchaseText = `Tu pedido ha sido procesado exitosamente. Te llegara una
            notificación para pagar tu pedido.`
          return
        }

        if (["reward_points", "walleat"].includes(this.prevPaymentMethod)) {
          this.purchaseText = `No cuentas con saldo suficiente. Por favor, realiza el pago con CoDi o selecciona otro método de pago`
          return
        }

        if (this.prevPaymentMethod === "reward_points") {
          this.purchaseText = `Tu orden será procesada en breve. Si no cuentas con puntos Walleat suficientes en unos momentos más llegará una notificación en tu aplicación bancaria. Autoriza el cobro en tu celular para poder procesar tu compra`
        } else if (
          this.prevPaymentMethod === "bank_deposit" ||
          this.prevPaymentMethod === "bankcard"
        ) {
          this.purchaseText = `Pago pendiente. Realiza el pago para completar tu compra.`
          this.instructions = pendingOrder.payment?.instructions
        } else {
          this.purchaseText = `Tu pedido ha sido procesado exitosamente. Te hemos enviado un correo con los detalles de tu compra.`
        }

        this.$bvModal.show("purchase-overview-modal")
      } catch (e) {
        console.error("Error completing checkout")
      } finally {
        this.$swal.close()
        this.visibleSidebar = false

        this.loading = false
        this.selectedPaymentMethod = ""
      }
    },

    handleCancelCodiModal() {
      this.$bvModal.hide("complete-codi-payment")
    },

    async handleStripePayment(paymentType, pendingOrder) {
      if (paymentType !== "bank_deposit" && paymentType !== "bankcard") return

      try {
        this.createConnection(pendingOrder)
        await this.waitForCoDiModalToClose()
      } catch (error) {
        console.error("Error handling CoDi payment:", error)
      }
    },

    async handleCoDiPayment(paymentType, pendingOrder) {
      const allowedPaymentTypes = ["codi", "reward_points", "walleat"]
      if (!allowedPaymentTypes.includes(paymentType)) return

      this.codiQrcode = pendingOrder.payment?.qrcode
      if (!this.codiQrcode) return

      if (["reward_points", "walleat"].includes(paymentType)) {
        this.codiType = "qrcode"

        this.warningToast(
          "Atención",
          "No cuentas con el saldo suficiente. Por favor, realiza el pago con CoDi o selecciona otro método de pago"
        )
      }

      try {
        this.createConnection(pendingOrder)

        this.$bvModal.show("codi-purchase-qrcode")

        await this.waitForCoDiModalToClose()
      } catch (error) {
        console.error("Error handling CoDi payment:", error)
        this.publicConnection.close()
        this.$bvModal.hide("codi-purchase-qrcode")
      }
    },

    waitForCoDiModalToClose() {
      return new Promise((resolve) => {
        this.modalClosedResolve = resolve
      })
    },

    createConnection(response) {
      this.publicConnection = new WebSocket(
        `${process.env.VUE_APP_WSS_URL}/cable?channel=PublicChannel`
      )

      this.publicConnection.onopen = () => this.handleWebSocketOpen(response)
      this.publicConnection.onmessage = this.handleWebSocketMessage
    },

    handleWebSocketOpen(response) {
      console.log("Successfully connected to the echo websocket server...")

      this.publicConnection.send(
        JSON.stringify({
          command: "subscribe",
          identifier: JSON.stringify({
            channel: "PublicChannel",
            unique_id: response.id,
          }),
        })
      )

      console.log("Successfully sent...")
    },

    handleWebSocketMessage(event) {
      try {
        const data = JSON.parse(event.data)

        const notificationType = data.message?.notification_type
        const jsonMessage = data.message?.json_message

        switch (notificationType) {
          case "stripe_purchase":
            this.purchaseText = `Tu pedido con folio ${jsonMessage?.folio} ha sido procesado exitosamente. Te hemos enviado un correo con los detalles de tu compra.`
            this.$bvModal.show("purchase-overview-modal")
            const prevOrder = this.prevOrder
            this.prevOrder = jsonMessage?.id ?? prevOrder
            this.publicConnection.close()
            break
          case "codi_purchase":
            this.$root.$once("bv::modal::hidden", (bvEvent, modalId) => {
              if (modalId === "codi-purchase-qrcode") {
                this.modalClosedResolve()
              }
            })
            this.$bvModal.hide("codi-purchase-qrcode")
            this.publicConnection.close()
          case "codi_purchase_rejected":
            this.generatePaymentRejectionSwal(data.message.rejection_reason)
            this.publicConnection.close()
            break
          default:
            console.log("Unknown notification type:", notificationType)
        }
      } catch (error) {
        console.error("Error parsing WebSocket message:", error)
      } finally {
        this.loading = false
      }
    },

    generatePaymentRejectionSwal(message) {
      this.$swal({
        title: "Pago declinado...",
        text: message,
        icon: "error",
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: false,
        willClose: () => {
          this.$bvModal.hide("purchase-overview-modal")
        },
      })
    },

    handleSelectedPaymentMethod(paymentMethod) {
      this.selectedPaymentMethod = paymentMethod
    },

    handleSetAddress(address) {
      const { id, name, email, cel_number, ...new_address } = address

      const { cel_number: prev_cel_number, email: prev_email } = this.address

      if (prev_cel_number) {
        this.order.cel_number = prev_cel_number
      } else if (cel_number) {
        this.order.cel_number = cel_number
      }

      if (prev_email) {
        this.order.email = prev_email
      } else if (email) {
        this.order.email = email
      }

      this.order = {
        ...this.order,
        name,
      }

      this.address = {
        ...this.address,
        ...new_address,
      }

      this.localAddress = {
        ...this.localAddress,
        ...new_address,
      }

      const { formatted_address } = this.address

      if (formatted_address) {
        this.$refs.googleAddress.$el.children[0].value = formatted_address
      }
    },
    handleSidebarHidden() {
      this.selectedPaymentMethod = ""
      document.body.style.overflow = "auto"
      this.visibleSidebar = false

      this.$refs.wizard.navigateToStep(0)
    },
    handleSidebarVisible() {
      document.body.style.overflow = "hidden"
    },

    handleAddressSelection(address) {
      this.setAddressForEdit(address, true)
    },
    setAddressForEdit(place, isDefault = false) {
      if (isDefault) {
        this.center = {
          lat: parseFloat(place.lat),
          lng: parseFloat(place.lng),
        }
        this.marker = {
          lat: parseFloat(place.lat),
          lng: parseFloat(place.lng),
        }
        this.googleAddress = this.getAddress(place, true)
      } else {
        this.center = {
          lat: place.geometry.location.lat(),
          lng: place.geometry.location.lng(),
        }
        this.marker = {
          lat: place.geometry.location.lat(),
          lng: place.geometry.location.lng(),
        }

        this.googleAddress = this.getAddress(place)
      }
      this.zoom = 16
      this.handleSetAddress(this.googleAddress)
    },
    getAddress(place, isDefault = false) {
      if (isDefault) {
        return {
          name: "",
          state: place.state,
          country: place.country,
          city: place.city,
          street: place.street,
          ext_number: place.ext_number,
          int_number: place.int_number,
          postal_code: place.postal_code,
          lat: parseFloat(place.lat),
          lng: parseFloat(place.lng),
          suburb: place.suburb,
          url: place.url,
          formatted_address: place.formatted_address || "",
        }
      }

      const address = {
        name: "",
        state: "",
        country: "",
        city: "",
        street: "",
        ext_number: "",
        int_number: "",
        postal_code: "",
        lat: 19.4326077,
        lng: -99.133208,
        suburb: "",
        url: "",
        formatted_address: "",
      }
      place.address_components.forEach((x) => {
        if (x.types.includes("administrative_area_level_1")) {
          address.state = x.long_name
        }
        if (x.types.includes("country")) {
          address.country = x.long_name
        }
        if (x.types.includes("city") || x.types.includes("locality")) {
          address.city = x.long_name
        }
        if (x.types.includes("street") || x.types.includes("route")) {
          address.street = x.long_name
        }
        if (
          x.types.includes("ext_number") ||
          x.types.includes("street_number")
        ) {
          address.ext_number = x.long_name
        }
        if (x.types.includes("postal_code")) {
          address.postal_code = x.long_name
        }
        if (
          x.types.includes("suburb") ||
          x.types.includes("sublocality_level_1")
        ) {
          address.suburb = x.long_name
        }
      })
      address.formatted_address = place.formatted_address
      address.lat = place.geometry.location.lat()
      address.lng = place.geometry.location.lng()
      address.url = place.url
      return address
    },
    setMarker(event) {
      const mapsapi = new getGoogleMapsAPI()
      const latlng = { lat: event.latLng.lat(), lng: event.latLng.lng() }
      mapsapi.maps.Geocoder.prototype.geocode(
        { location: latlng },
        (results, status) => {
          if (status === "OK") {
            if (results[1]) {
              this.$refs.googleAddress.$el.children[0].value =
                results[1].formatted_address
              this.googleAddress = this.getAddress(results[1])
              const lat = event.latLng.lat()
              const lng = event.latLng.lng()
              this.googleAddress.google_maps_url = `https://www.google.com/maps/search/?api=1&query=${lat},${lng}`
              this.handleSetAddress(this.googleAddress)
              this.marker = {
                lat,
                lng,
              }
            }
          }
        }
      )
    },
  },
}
</script>

<style lang="scss">
.wizard-tab {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.vue-form-wizard {
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.wizard-tab-content {
  padding: 1rem !important;
}

.sidebar__body {
  max-height: 100vh;
  overflow-y: auto;
}

.cart__container {
  height: 100%;
  display: grid;
  grid-template-rows: 1fr auto;
  overflow: hidden;
}

.cart__sidebar {
  width: auto;
  max-height: 100vh !important;
}
@media (max-width: 768px) {
  .cart__sidebar {
    width: 100%;
  }
}

.pac-target-input {
  color: #495057;
  background-color: #fff;
  border: 1px solid #404656;

  padding: 0.438rem 1rem;
  border-radius: 0.357rem;
  width: 100%;
}

.dark-layout {
  .pac-target-input {
    color: #b4b7bd;
    background-color: #283046;
    border: 1px solid #404656;
  }
}
</style>

<style lang="scss" scoped>
.cart-modal-body {
  display: grid;
  grid-template-rows: auto 1fr auto;
}

@media (max-width: 768px) {
  .cart-modal-body {
    max-height: 65vh;
    min-height: 65vh;
  }
}
</style>
