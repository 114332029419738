<template>
  <div v-if="currentStore">
    <div
      class="payments__grid"
      v-if="currentStore.accepted_payment_types.length > 0"
    >
      <PaymentButton
        dataTestId="codi-button"
        buttonText="Codi"
        :imageSrc="require('@/assets/images/logo/CODI.svg')"
        v-b-modal.complete-codi-payment
        v-if="showCodiButton"
      />

      <PaymentButton
        dataTestId="cash-button"
        buttonText="Efectivo"
        :buttonVariant="
          selectedPaymentMethod === 'cash' ? 'primary' : 'outline-primary'
        "
        :imageSrc="require('@/assets/images/logo/CASH.svg')"
        @click="handleSelectedPaymentMethod('cash')"
        v-if="showCashButton"
      />

      <PaymentButton
        dataTestId="bank-card-button"
        buttonText="Tarjeta"
        :buttonVariant="
          selectedPaymentMethod === 'bankcard' ? 'primary' : 'outline-primary'
        "
        :imageSrc="require('@/assets/images/logo/bank_card.png')"
        @click="handleSelectedPaymentMethod('bankcard')"
        v-if="showBankCardButton"
      />

      <PaymentButton
        dataTestId="reward-points-button"
        buttonText="Puntos de recompensa"
        :buttonVariant="
          selectedPaymentMethod === 'reward_points'
            ? 'primary'
            : 'outline-primary'
        "
        :imageSrc="require('@/assets/images/logo/reward_points.png')"
        @click="handleSelectedPaymentMethod('reward_points')"
        v-if="showRewardPointsButton"
      />

      <PaymentButton
        dataTestId="bank-deposit-button"
        buttonText="Transferencia/depósito"
        :buttonVariant="
          selectedPaymentMethod === 'bank_deposit'
            ? 'primary'
            : 'outline-primary'
        "
        :imageSrc="require('@/assets/images/logo/bank_transfer.png')"
        @click="handleSelectedPaymentMethod('bank_deposit')"
        v-if="showBankDepositButton"
      />
    </div>

    <div
      v-if="
        currentStore.accepted_payment_types.length === 0 ||
        (currentStore.accepted_payment_types[0] === 'bankcard' && !storeAccount)
      "
    >
      <b-alert
        show
        variant="warning"
        class="mt-2"
        :title="`No se han configurado métodos de pago para ${currentStore.name}`"
      >
        <p>
          Por favor, contacta a la tienda para que configure los métodos de
          pago.
        </p>
      </b-alert>
    </div>

    <codi-payment-modal
      :order="order"
      :loading="loading"
      :completeOnlineOrder="completeCheckout"
      :handleCancelCodiModal="handleCancelCodiModal"
    />
    <codi-info-modal />
  </div>
</template>

<script>
// Components
import PaymentButton from "@/@core/components/checkout/PaymentButton.vue"

import CodiInfoModal from "@/@core/components/WallEateCommerce/CodiInfoModal.vue"
import CodiPaymentModal from "@/@core/components/WallEateCommerce/CodiPaymentModal.vue"

// Mixins
import paymentMethodsMixin from "@/@core/mixins/paymentMethodsMixin"

export default {
  components: {
    PaymentButton,

    CodiInfoModal,
    CodiPaymentModal,
  },
  props: {
    selectedPaymentMethod: {
      type: String,
      required: true,
    },
    order: {
      type: Object,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
    completeCheckout: {
      type: Function,
      required: true,
    },
    handleCancelCodiModal: {
      type: Function,
      required: true,
    },
    handleSelectedPaymentMethod: {
      type: Function,
      required: true,
    },
  },
  mixins: [paymentMethodsMixin],
  watch: {
    selectedPaymentMethod(val) {
      this.$emit("update:selectedPaymentMethod", val)
    },
  },
}
</script>

<styles lang="scss" scoped>
.payments__grid {
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  gap: 10px;

  @media (min-width: 480px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (min-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }
}
</styles>
